<template>
  <v-dialog persistent v-model="showed" width="360">
    <v-card relative>
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text>{{ text }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="hasNegative" @click="onNegative">{{ negativeText || 'Cancel' }}</v-btn>
        <v-btn :loading="loading" :color="color || 'primary'" @click="onPositive">{{ positiveText || 'OK' }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['color', 'positive', 'positiveText', 'negative', 'negativeText'],
  data() {
    let hasNegative = typeof this.negative === 'function';
    return {
      showed: false,
      loading: false,
      hasNegative: hasNegative,
      title: '',
      text: '',
      payload: null,
    };
  },
  methods: {
    show(opt) {
      if (!opt) return;
      if (typeof opt === 'string') {
        this.title = 'Book editor';
        this.text = opt;
        this.payload = null;
      } else {
        this.title = opt.title || 'Book editor';
        this.text = opt.text;
        this.payload = opt.payload;
      }
      this.showed = true;
    },
    hide(opt) {
      this.showed = false;
    },
    onPositive() {
      if (typeof this.positive === 'function') {
        this.positive(this.payload);
      }
      this.showed = false;
    },
    onNegative() {
      if (typeof this.negative === 'function') {
        this.negative(this.payload);
      }
      this.showed = false;
    },
  },
};
</script>
