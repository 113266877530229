/* eslint-disable prettier/prettier */
import BObject from './BObject';
import Styleable from './Styleable';


export default class BText extends BObject {

	constructor(props) {
		super(props);
		this.textTemplate = props.textTemplate || '';
		this.textData = props.textData || [];
		this.style = new Styleable(props.style);
	}

	render() {
		let template = '<foreignObject x="$x" y="$y" width="$width" height="$height"><div xmlns="http://www.w3.org/1999/xhtml" style="$style">$text</div></foreignObject>';
		return template.replace(/\$id/g, this.id)
			.replace(/\$x/g, this.x)
			.replace(/\$y/g, this.y)
			.replace(/\$width/g, this.width)
			.replace(/\$height/g, this.height)
			.replace(/\$style/g, this.style.toString())
			.replace(/\$text/g, this.text);
	}

}
