<template>
  <div class="be-btn-group be-insert-menu">
    <span class="be-btn be-btn-primary be-dropdown-toggle" data-toggle="dropdown">
      <i class="fas fa-plus mr-2 be-icon"></i>Add
    </span>
    <ul class="be-dropdown-menu">
      <li v-for="item in items" :key="item.type">
        <a @click="onClick(item)">
          <i :class="item.icon"></i>
          {{ item.name }}
        </a>
      </li>
    </ul>
    <CharacterModal ref="characters" :select="onSelectCharacter" />
    <Uploader ref="uploader" :uploaded="onUploaded" />
  </div>
</template>

<script>
import CharacterModal from '../CharacterModal';
import BText from '../bookify/BText';
import BImage from '../bookify/BImage';
import Uploader from '../Uploader';

export default {
  components: { CharacterModal, Uploader },
  data() {
    return {
      items: [
        {
          type: 'text',
          name: 'Text',
          icon: 'fas fa-text-width mr-2 be-icon',
        },
        {
          type: 'image.other',
          name: 'Image',
          icon: 'fas fa-image mr-2 be-icon',
        },
        {
          type: 'image.character',
          name: 'Character Image',
          icon: 'fas fa-user mr-2 be-icon',
        },
      ],
    };
  },
  computed: {
    nextOrdinal() {
      let blayout = this.$store.state.bookeditor.blayout;
      return blayout.objects.length + 1;
    },
    objects() {
      return this.$store.state.bookeditor.blayout?.objects || [];
    },
  },
  methods: {
    presert(obj) {
      this.$store.commit('bookeditor/presert', obj);
    },
    createId(prefix) {
      let ids = this.objects.map(obj => obj.id);
      let idx = ids.length;
      let id;
      do {
        id = prefix + '-' + idx;
        idx++;
      } while (ids.indexOf(id) >= 0);
      return id;
    },
    onClick(item) {
      if (item.type === 'text') {
        let obj = new BText({
          id: this.createId('text'),
          objectType: 'text',
          textTemplage: 'New text',
          width: 200,
          height: 200,
          x: 0,
          y: 0,
          ordinal: this.nextOrdinal,
        });
        this.presert(obj);
      } else if (item.type === 'image.other') {
        this.$refs.uploader.browse();
      } else if (item.type === 'image.character') {
        this.$refs.characters.show();
      }
    },
    onSelectCharacter(char) {
      if (!char) return;
      let resources = [...char.propertyAvatar];
      let obj = new BImage({
        id: this.createId('image'),
        objectType: 'image',
        targetCharacter: { code: char.code },
        characterResources: resources,
        width: 200,
        height: 200,
        x: 0,
        y: 0,
        ordinal: this.nextOrdinal,
      });
      this.presert(obj);
    },
    onUploaded(data) {
      if (!data) return;
      let obj = new BImage({
        id: this.createId('image'),
        objectType: 'image',
        other: { imageSrc: data.src },
        width: 200,
        height: 200,
        x: 0,
        y: 0,
        ordinal: this.nextOrdinal,
      });
      this.presert(obj);
    },
  },
};
</script>
