f<template>
  <span class="be-picker mb-2" @click="onClick"><i :class="icon"></i>{{ text }}</span>
</template>

<script>
export default {
  props: ['text', 'icon', 'click'],
  methods: {
    onClick(e) {
      e.preventDefault();
      if (typeof this.click === 'function') {
        this.click();
      }
    },
  },
};
</script>

<style lang="scss">
.be-picker {
  display: block;
  width: 100%;
  height: 36px;
  padding: 5px 15px;
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  background-color: #fff;
  color: #333;
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
  &:hover {
    background-color: #f5f5f5;
    border-color: #ccc;
  }
}
</style>
