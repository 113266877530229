import storeRequest from './request/storeRequest';
import STORAGE_NAME from '@/const/storage';
import router from '@/router';

let storeId = localStorage.getItem(STORAGE_NAME.STORE_ID);
storeRequest.setStoreId(storeId);

// Create a book
function createBook(data) {
  return storeRequest.post('/books', {
    book: data,
  });
}

// Update a book
function updateBook(id, data) {
  return storeRequest.put('/books/' + id, {
    book: data,
  });
}

function getBook(id) {
  return storeRequest.get('/books/' + id);
}
function deleteBook(id) {
  return storeRequest.delete('/books/' + id);
}
function getBooks(params) {
  return storeRequest.get('/books', {
    params: { ...params },
  });
}

function getPages(bookId) {
  return storeRequest.get('/books/' + bookId + '/pages');
}

function getPage(bookId, pageId) {
  return storeRequest.get('/books/' + bookId + '/pages/' + pageId);
}

function createPage(bookId, data) {
  return storeRequest.post('/books/' + bookId + '/pages', { page: data });
}

function updatePage(bookId, pageId, data) {
  return storeRequest.put('/books/' + bookId + '/pages/' + pageId, { page: data });
}

function deletePage(bookId, pageId) {
  return storeRequest.delete('/books/' + bookId + '/pages/' + pageId);
}

function getLayouts(bookId) {
  return storeRequest.get('/books/' + bookId + '/page-layouts');
}

function getLayout(bookId, layoutId) {
  return storeRequest.get('/books/' + bookId + '/page-layouts/' + layoutId);
}

function createLayout(bookId, data) {
  return storeRequest.post('/books/' + bookId + '/page-layouts', { pageLayout: data });
}

function updateLayout(bookId, layoutId, data) {
  return storeRequest.put('/books/' + bookId + '/page-layouts/' + layoutId, { pageLayout: data });
}

function deleteLayout(bookId, layoutId) {
  return storeRequest.delete('/books/' + bookId + '/page-layouts/' + layoutId);
}

function getChapters(bookId) {
  return storeRequest.get('/books/' + bookId + '/chapters');
}

function getChapter(bookId, chapterId) {
  return storeRequest.get('/books/' + bookId + '/chapters/' + chapterId);
}

function createChapter(bookId, data) {
  return storeRequest.post('/books/' + bookId + '/chapters', { chapter: data });
}

function updateChapter(bookId, chapterId, data) {
  return storeRequest.put('/books/' + bookId + '/chapters/' + chapterId, { chapter: data });
}

function deleteChapter(bookId, chapterId) {
  return storeRequest.delete('/books/' + bookId + '/chapters/' + chapterId);
}

function getCharacters(bookId) {
  return storeRequest.get('/books/' + bookId + '/characters');
}

function getCharacter(bookId, characterId) {
  return storeRequest.get('/books/' + bookId + '/characters/' + characterId);
}

function createCharacter(bookId, data) {
  return storeRequest.post('/books/' + bookId + '/characters', { character: data });
}

function updateCharacter(bookId, characterId, data) {
  return storeRequest.put('/books/' + bookId + '/characters/' + characterId, { character: data });
}

function deleteCharacter(bookId, characterId) {
  return storeRequest.delete('/books/' + bookId + '/characters/' + characterId);
}

function getGroups(bookId) {
  return storeRequest.get('/books/' + bookId + '/character-groups');
}

function getGroup(bookId, groupId) {
  return storeRequest.get('/books/' + bookId + '/character-groups/' + groupId);
}

function createGroup(bookId, data) {
  return storeRequest.post('/books/' + bookId + '/character-groups', { characterGroup: data });
}

function updateGroup(bookId, groupId, data) {
  return storeRequest.put('/books/' + bookId + '/character-groups/' + groupId, { characterGroup: data });
}

function deleteGroup(bookId, groupId) {
  return storeRequest.delete('/books/' + bookId + '/character-groups/' + groupId);
}

function uploadImage(bookId, data) {
  return storeRequest.post('/images', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}
function previewLayoutBook(bookId, data) {
  return storeRequest.post('/books/' + bookId + '/page-layouts/preview', { pageLayout: data }, { a: 121 });
}
export const bookApi = {
  createBook,
  getBooks,
  getBook,
  deleteBook,
  updateBook,
  getPages,
  getPage,
  createPage,
  updatePage,
  deletePage,
  getLayouts,
  getLayout,
  createLayout,
  updateLayout,
  deleteLayout,
  getChapters,
  getChapter,
  createChapter,
  updateChapter,
  deleteChapter,
  getCharacters,
  getCharacter,
  createCharacter,
  updateCharacter,
  deleteCharacter,
  getGroups,
  getGroup,
  createGroup,
  updateGroup,
  deleteGroup,
  uploadImage,
  previewLayoutBook,
};
