<template>
  <div class="be-toolbar">
    <InsertMenu :click="setModeInsert" />
    <div class="be-btn-group">
      <ToolbarAction icon="fas fa-mouse-pointer" :click="setModeSelect" />
      <ToolbarAction icon="far fa-square" :click="clearSelection" />
    </div>
    <div class="be-btn-group">
      <ToolbarAction icon="fas fa-print" />
      <ToolbarAction icon="fas fa-fill" />
      <ToolbarAction icon="fas fa-image" />
    </div>
  </div>
</template>

<script>
import InsertMenu from './InsertMenu';
import ToolbarAction from './ToolbarAction';

export default {
  components: {
    InsertMenu,
    ToolbarAction,
  },
  methods: {
    setModeInsert(type) {
      this.$store.commit('bookeditor/setMode', 'insert', type);
    },
    setModeSelect() {
      this.$store.commit('bookeditor/setMode', 'select');
    },
    clearSelection() {
      this.$store.commit('bookeditor/clearSelection');
    },
  },
};
</script>
