<template>
  <span class="be-btn be-btn-default" v-on:click="click">
    <i :class="icon + ' be-icon'"></i>
  </span>
</template>

<script>
export default {
  props: ['icon', 'click'],
};
</script>
