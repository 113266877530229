/* eslint-disable prettier/prettier */
import BObject from './BObject';
import imageHelpers from '@/helpers/image';


export default class BImage extends BObject {

	constructor(props) {
		super(props);
		this.preserveAspectRatio = props.preserveAspectRatio || 'XMidYMid meet';
		this.targetCharacter = props.targetCharacter;
		this.characterResources = props.characterResources || [];
		this.other = props.other;
		this.src = this.defaultSource();
	}

	defaultSource() {
		if (!this.characterResources || this.characterResources.length == 0) return '';
		for (let group of this.characterResources) {
			if (!group.data || group.data.length == 0) continue;
			for (let item of group.data) {
				return item.dataCode;
			}
		}
		return '';
		
	}

	isOther() {
		return (this.other && this.other.imageSrc && this.other.imageSrc != '') ? true : false;
	}

	getSource() {
		if (this.isOther()) {
			return imageHelpers.url(this.other.imageSrc, { width: 500, height: 500 });
		}
		if (!this.characterResources || this.characterResources.length == 0) return '';
		for (let group of this.characterResources) {
			if (!group.data || group.data.length == 0) continue;
			for (let item of group.data) {
				if (item.dataCode === this.src && item.dataValue && item.dataValue != '') {
					return imageHelpers.url(item.dataValue, { width: 500, height: 500 });
				}
			}
		}
		return '';
	}

	render() {
		let template = '<image id="$id" x="$x" y="$y" width="$width" height="$height" preserveAspectRatio="$aspectRatio" xlink:href="$src" />';
		return template.replace(/\$id/g, this.id)
			.replace(/\$x/g, this.x)
			.replace(/\$y/g, this.y)
			.replace(/\$width/g, this.width)
			.replace(/\$height/g, this.height)
			.replace(/\$preserveAspectRatio/g, this.preserveAspectRatio)
			.replace(/\$src/g, this.getSource());
	}

}
