<template>
  <input type="file" name="file" ref="file" style="display: none;" @change="uploadFile" />
</template>

<script>
import axios from 'axios';
import { uploadService } from '@/apis/upload.s';

export default {
  props: ['uploaded'],
  methods: {
    hex(value) {
      return Math.floor(value).toString(16);
    },
    objectId() {
      return this.hex(Date.now() / 1000) + ' '.repeat(16).replace(/./g, () => this.hex(Math.random() * 16));
    },
    browseFile() {
      $(this.$refs.file).trigger('click');
    },
    browse() {
      $(this.$el).trigger('click');
    },
    async uploadFile(e) {
      let file = e.target.files.length > 0 ? e.target.files[0] : null;
      if (!file) return;
      e.target.value = null;
      try {
        let parts = file.name.toLowerCase().split('.');
        if (parts.length < 2) return;
        let type = parts[parts.length - 1];
        let id = this.objectId();
        let urlData = await uploadService.get({ filename: `lattehub-image-${id}.${type}` });
        if (urlData) {
          let res = await axios.put(urlData.data.presignedUrl, file, {
            headers: {
              'Content-Type': `${file.type}`,
            },
          });
          let data = {
            id: id,
            src: urlData.data.imagePath,
            filename: file.name,
          };
          if (typeof this.uploaded === 'function') this.uploaded(data);
        }
      } catch (err) {
        this.errors = err;
      }
    },
  },
};
</script>
