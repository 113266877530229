/* eslint-disable prettier/prettier */
export default class BObject {

	constructor(props = {}) {
		this._id = props._id;
		this.id = props.id;
		this.objectType = props.objectType || 'object';
		this.x = Number(props.x);
		this.y = Number(props.y);
		this.width = Number(props.width);
		this.height = Number(props.height);
		this.ordinal = props.ordinal || 0;
		this.visible = true;
	}

	styleable() {
		return {};
	}

	render() {
		return '';
	}

	toSVG() {
		let { x, y, width, height } = this;
		let svg = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="$width" height="$height" viewBox="$x $y $width $height" xml:space="preserve">$object</svg>';
		return svg.replace(/\$width/g, width)
			.replace(/\$height/g, height)
			.replace(/\$x/g, x)
			.replace(/\$y/g, y)
			.replace(/\$object/g, this.render());
	}

}
