<template>
  <div class="be-panel" v-if="object">
    <div class="be-panel-header">
      <h3 class="be-panel-title">PROPERTIES</h3>
    </div>
    <div class="be-panel-body noscrollbar">
      <BImageForm v-if="object.objectType === 'image'" :object="object" />
      <BTextForm v-if="object.objectType === 'text'" :object="object" />
    </div>
  </div>
</template>

<script>
import BImageForm from './BImageForm';
import BTextForm from './BTextForm';

export default {
  components: {
    BImageForm,
    BTextForm,
  },
  computed: {
    object() {
      let { blayout } = this.$store.state.bookeditor;
      if (!blayout || !blayout.objects || blayout.objects.length == 0) return null;
      let selected = this.$store.state.bookeditor.selected;
      if (!selected || selected.length != 1) return null;
      for (let obj of blayout.objects) {
        if (obj.id === selected[0]) return obj;
      }
      return null;
    },
  },
};
</script>
