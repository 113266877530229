/* eslint-disable prettier/prettier */

const ATTRS = {
	color: {
		css: 'color',
		default: '#000000',
	},
	backgroundColor: {
		css: 'background-color',
		default: 'transparent',
	},
	fontFamily: {
		css: 'font-family',
		default: 'Arial',
	},
	fontSize: {
		css: 'font-size',
		default: '16px',
	},
	fontWeight: {
		css: 'font-weight',
		default: '500',
	},
	fontStyle: {
		css: 'font-style',
		default: 'normal',
	},
	lineHeight: {
		css: 'line-height',
		default: '1.5',
	},
	textAlign: {
		css: 'text-align',
		default: 'left',
	},
};

const CSS_ATTRS = () => {
	let css = {};
	for (let attr in ATTRS) {
		css[ATTRS[attr].css] = attr;
	}
	return css;
};

export default class Styleable {

	constructor(css) {
		for (let attr in ATTRS) {
			this[attr] = ATTRS[attr].default;
		}
		let parts = css ? css.split(';') : [];
		parts = parts.map(part => {
			let idx = part.indexOf(':');
			if (idx < 1 || idx >= part.length) return null;
			let attr = part.substr(0, idx).trim();
			let val = part.substr(idx + 1).trim();
			return { attr, val };
		});
		const cssattrs = CSS_ATTRS();
		for (let p of parts) {
			if (!p || p.attr == '' || p.val == '') continue;
			let attr = cssattrs[p.attr];
			if (!attr) continue;
			this[attr] = p.val;
		}
	}

	toString() {
		let res = [];
		for (let attr in ATTRS) {
			res.push(ATTRS[attr].css + ':' + this[attr]);
		}
		return res.join(';');
	}

}
