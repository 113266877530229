/* eslint-disable prettier/prettier */
import BText from './BText';
import BImage from './BImage';


export default class BLayout {

	constructor(props = {}) {
		this._id = props._id;
		this.width = props.width;
		this.height = props.height;
		this.background = props.background;
		this.backgroundSrc = props.backgroundSrc;
		this.objects = [];
		let objects = props.objects || [];
		for (let obj of objects) {
			if (obj.objectType === 'image') {
				this.objects.push(new BImage(obj));
			} else if (obj.objectType === 'text') {
				this.objects.push(new BText(obj));
			}
		}
	}

	render() {
		return this.objects.map(obj => obj.render()).join('');
	}

	toSVG(options = null) {
		let { style } = this;
		let bg = style.bgColor ? '<path fill="$bgColor" d="M0 0h$widthv$heighth-$widthv-$heightz" />' : '<path d="M$x $yh$widthv$heighth-$widthv-$heightz" />';
		if (style.bgImage) {
			bg += '<image x="0" y="0" width="$width" height="$height" preserveAspectRatio="$par" xlink:href="$bgImage" />';
		}
		let page = '<g><g>$bg</g><g>$objects</g></g>';
		let svg = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="$width" height="$height" viewBox="0 0 $width $height" xml:space="preserve">$page</svg>';
		return svg.replace(/\$page/g, page)
			.replace(/\$bg/g, bg)
			.replace(/\$width/g, style.width)
			.replace(/\$height/g, style.height)
			.replace(/\$bgColor/g, style.bgColor)
			.replace(/\$bgImage/g, style.bgImage)
			.replace(/\$par/g, style.par === 'preserve' ? 'XMidYMid meet' : 'none')
			.replace(/\$objects/g, this.render());
	}

}
