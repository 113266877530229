<template>
  <div class="book-editor">
    <BookEditor v-if="blayout" />
  </div>
</template>

<script>
import { bookApi } from '@/apis/book';
import DataParser from './components/BookEditor/bookify/data-parser';
import BookEditor from './components/BookEditor';

export default {
  components: { BookEditor },
  computed: {
    blayout() {
      return this.$store.state.bookeditor.blayout;
    },
  },
  methods: {
    async loadData() {
      let { bookId, layoutId } = this.$route.params;
      try {
        let res = await bookApi.getLayout(bookId, layoutId);
        let pageLayout = res.data.pageLayout;
        if (!pageLayout) return;
        res = await bookApi.getBook(bookId);
        let book = res.data.book;
        res = await bookApi.getPage(bookId, pageLayout.page);
        let page = res.data.page;
        res = await bookApi.getCharacters(bookId);
        let characters = res.data.characters;
        this.$store.commit('bookeditor/open', {
          book: book,
          page: page,
          pageLayout: pageLayout,
          characters: characters,
          blayout: DataParser.createLayout(pageLayout, page, book),
        });
      } catch (err) {
        return;
      }
    },
  },
  created() {
    this.loadData();
  },
};
</script>

<style>
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  height: 100%;
  overflow: hidden;
}

body {
  height: 100%;
  margin: 0;
}
</style>
