<template>
  <div>
    <Picker icon="fas fa-font mr-2" :text="displayName" :click="show" />
    <v-dialog v-model="showed" width="360">
      <v-card>
        <v-card-title>Font settings</v-card-title>
        <v-container fluid>
          <v-row>
            <v-col cols="12">
              <v-text-field name="fontFamily" label="Font family" v-model="model.fontFamily" />
            </v-col>
            <v-col cols="6">
              <v-text-field name="fontSize" label="Font size" v-model="model.fontSize" />
            </v-col>
            <v-col cols="6">
              <v-text-field name="fontWeight" label="Font weight" v-model="model.fontWeight" />
            </v-col>
            <v-col cols="6">
              <v-text-field name="lineHeight" label="Line height" v-model="model.lineHeight" />
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="onApply" color="primary">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Picker from './Picker';

export default {
  components: { Picker },
  props: ['font', 'apply'],
  data() {
    let font = this.font || {};
    return {
      showed: false,
      model: font,
    };
  },
  computed: {
    fontFamily() {
      return this.model.fontFamily;
    },
    fontSize() {
      return this.model.fontSize;
    },
    fontWeight() {
      return this.model.fontWeight;
    },
    lineHeight() {
      return this.model.lineHeight;
    },
    displayName() {
      let { fontFamily, fontSize, fontWeight } = this;
      return [fontFamily, fontSize, fontWeight].join(' ');
    },
  },
  methods: {
    show() {
      this.showed = true;
    },
    onApply(e) {
      e.preventDefault();
      this.showed = false;
      if (typeof this.apply === 'function') {
        this.apply(this.model);
      }
    },
  },
};
</script>
