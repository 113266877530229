<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col cols="12">
        <v-textarea name="text" label="Text" rows="4" v-model="object.textTemplate" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <FontPicker :font="object.style" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-select label="Align" v-model="object.style.textAlign" :items="aligns" />
      </v-col>
      <v-col cols="6">
        <v-text-field label="Color" v-model="object.style.color" v-mask="mask">
          <template v-slot:append>
            <v-menu v-model="colorPicker" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
              <template v-slot:activator="{ on }">
                <div class="be-swatch" :style="colorStyle" v-on="on" />
              </template>
              <v-card>
                <v-card-text class="pa-0">
                  <v-color-picker v-model="object.style.color" flat />
                </v-card-text>
              </v-card>
            </v-menu>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <v-text-field type="number" name="width" label="Width" v-model="object.width" />
      </v-col>
      <v-col cols="3">
        <v-text-field type="number" name="height" label="Height" v-model="object.height" />
      </v-col>
      <v-col cols="3">
        <v-text-field type="number" name="x" label="X" v-model="object.x" />
      </v-col>
      <v-col cols="3">
        <v-text-field type="number" name="y" label="Y" v-model="object.y" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FontPicker from './FontPicker';

export default {
  components: { FontPicker },
  props: ['object'],
  data() {
    return {
      colorPicker: false,
      aligns: [
        { text: 'Left', value: 'left' },
        { text: 'Right', value: 'right' },
        { text: 'Center', value: 'center' },
        { text: 'Justify', value: 'justify' },
      ],
    };
  },
  computed: {
    colorStyle() {
      return { backgroundColor: this.object?.style.color };
    },
  },
};
</script>

<style>
.be-swatch {
  width: 30px;
  height: 30px;
  border-radius: 4px;
  cursor: pointer;
}
</style>
