<template>
  <li :class="active ? 'be-layer noselect active' : 'be-layer noselect'" :data-id="object.id" @click="onClick">
    <div class="be-layer-left">
      <span @click="onVisible(object.id)">
        <i v-if="!object.visible" class="far fa-square be-icon"></i>
        <i v-if="object.visible" class="fas fa-eye be-icon"></i>
      </span>
    </div>
    <div class="be-layer-body">
      <span class="be-layer-label">{{ object.id }}</span>
    </div>
    <div class="be-layer-right">
      <span class="be-layer-action" @click="onDelete">
        <i class="fas fa-trash be-icon"></i>
      </span>
    </div>
  </li>
</template>

<script>
export default {
  props: ['object', 'active', 'click', 'delete', 'visible'],
  methods: {
    onVisible(id) {
      if (typeof this.visible === 'function') {
        this.visible(id);
      }
    },
    getIcon() {
      switch (this.object.type) {
        case 'text':
          return 'fa fa-file-text-o';
        case 'image':
          return 'fa fa-file-image-o';
        default:
          return 'fa fa-question';
      }
    },
    onClick(e) {
      e.preventDefault();
      if (typeof this.click === 'function') {
        this.click(this.object.id);
      }
    },
    onDelete(e) {
      e.preventDefault();
      if (typeof this.delete === 'function') {
        this.delete(this.object.id);
      }
    },
  },
};
</script>
