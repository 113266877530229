<template>
  <div class="be-panel" v-if="blayout">
    <div class="be-panel-header">
      <h3 class="be-panel-title">OBJECTS</h3>
    </div>
    <div class="be-panel-body noscrollbar">
      <ul class="be-layer-list" ref="list">
        <ObjectItem
          v-for="item in objects"
          :key="item.id"
          :object="item"
          :active="selected && selected.indexOf(item.id) > -1"
          :click="onClick"
          :visible="onVisible"
          :delete="onDelete"
        />
      </ul>
    </div>
    <AlertModal
      ref="confirm"
      color="error"
      :positive="confirmDelete"
      positiveText="Yes"
      :negative="cancelDelete"
      negativeText="No"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import ObjectItem from './ObjectItem';
import AlertModal from '../AlertModal';

export default {
  components: { ObjectItem, AlertModal },
  computed: {
    blayout() {
      return this.$store.state.bookeditor.blayout;
    },
    objects() {
      return _.reverse(this.blayout?.objects || []);
    },
    selected() {
      return this.$store.state.bookeditor.selected;
    },
  },
  methods: {
    onClick(id) {
      this.$store.commit('bookeditor/select', id);
    },
    onVisible(id) {
      this.$store.commit('bookeditor/visible', id);
    },
    onDelete(id) {
      this.$refs.confirm.show({
        text: 'Delete this object?',
        payload: id,
      });
    },
    confirmDelete(payload) {
      this.$store.commit('bookeditor/removeObject', payload);
    },
    cancelDelete(payload) {
      this.$refs.confirm.hide();
    },
  },
  mounted() {
    $(this.$refs.list).sortable({
      item: '.be-layer',
      ref: 'data-id',
    });
    $(this.$refs.list).on('sortable.drop', (e, data) => {
      this.$store.commit('bookeditor/swapObjects', data);
    });
  },
};
</script>
