<template>
  <v-container fluid>
    <v-row class="mt-2">
      <v-col cols="3">
        <v-text-field type="number" name="width" label="Width" v-model="object.width" />
      </v-col>
      <v-col cols="3">
        <v-text-field type="number" name="height" label="Height" v-model="object.height" />
      </v-col>
      <v-col cols="3">
        <v-text-field type="number" name="x" label="X" v-model="object.x" />
      </v-col>
      <v-col cols="3">
        <v-text-field type="number" name="y" label="Y" v-model="object.y" />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-select label="Aspect ratio" v-model="object.preserveAspectRatio" :items="ratios" />
      </v-col>
    </v-row>
    <v-row no-gutters v-if="!isCharacter">
      <v-col cols="6">
        <label for="">Image</label>
        <uploader ref="uploader" :uploaded="onImageUploaded" />
        <img
          v-if="object.other.imageSrc"
          style="width: 100%; height: auto;"
          :src="imageHelpers.url(object.other.imageSrc, { width: 500, height: 500 })"
          alt="Upload"
          @click="$refs.uploader.browse()"
        />
      </v-col>
    </v-row>
    <v-row no-gutters v-if="isCharacter">
      <v-col cols="12" v-for="(group, idx) in object.characterResources" :key="idx">
        <label for="">{{ group.name }}</label>
        <AvatarGrid :group="idx" :items="group.data || []" :uploaded="onCharacterUploaded" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Uploader from '../Uploader';
import imageHelpers from '@/helpers/image';
import { imageFake } from '@/const/image';
import AvatarGrid from './AvatarGrid';

export default {
  components: { AvatarGrid, Uploader },
  props: ['object'],
  data() {
    return {
      imageFake,
      imageHelpers,
      ratios: [
        { text: 'Fit', value: 'none' },
        { text: 'Preserve', value: 'XMidYMid meet' },
      ],
    };
  },
  computed: {
    isCharacter() {
      return this.object && typeof this.object.isOther === 'function' && !this.object.isOther();
    },
  },
  methods: {
    onImageUploaded(data) {
      if (!data || !this.object) return;
      this.object.other = { imageSrc: data.src };
    },
    onCharacterUploaded(group, idx, data) {
      this.object.characterResources[group].data[idx]._id = data.image;
      this.object.characterResources[group].data[idx].image = data.image;
      this.object.characterResources[group].data[idx].dataValue = data.imageSrc;
      this.object.characterResources = [...this.object.characterResources];
    },
  },
};
</script>
