<template>
  <div class="be-container">
    <div class="be-header">
      <a class="be-back"><i class="fas fa-arrow-left be-icon"></i></a>
      <h1 class="be-title">{{ title }}</h1>
      <span class="be-btn be-btn-primary be-save" @click="saveData">Save</span>
    </div>
    <Toolbar />
    <div class="be-body">
      <div class="be-left">
        <ObjectPanel />
      </div>
      <PageEditor />
      <div class="be-right">
        <PropPanel />
      </div>
    </div>
  </div>
</template>

<script>
import PageEditor from './PageEditor';
import Toolbar from './Toolbar';
import ObjectPanel from './ObjectPanel';
import PropPanel from './PropPanel';
import { bookApi } from '@/apis/book';
import DataParser from './bookify/data-parser';

export default {
  components: { ObjectPanel, PageEditor, PropPanel, Toolbar },
  computed: {
    title() {
      let { page, pageLayout } = this.$store.state.bookeditor;
      if (!page || !pageLayout) return 'New layout';
      return 'Page ' + page.pageNumber + ' - ' + pageLayout.code;
    },
    blayout() {
      return this.$store.state.bookeditor.blayout;
    },
    pageLayout() {
      return this.$store.state.bookeditor.pageLayout;
    },
  },
  methods: {
    async saveData() {
      try {
        let data = DataParser.toPageLayout(this.pageLayout, this.blayout);
        let book = this.pageLayout.book;
        let id = this.pageLayout._id;
        await bookApi.updateLayout(book, id, data);
        alert('Saved');
      } catch (err) {
        alert(JSON.stringify(err));
      }
    },
  },
};
</script>
