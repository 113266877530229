<template>
  <v-dialog persistent v-model="showed" width="360">
    <v-card relative>
      <v-card-title>Select a character</v-card-title>
      <v-container fluid class="ma-0 pa-0">
        <v-row no-gutters class="ma-0 pa-0">
          <v-col cols="12" class="ma-0 pa-0">
            <v-list two-line>
              <template v-for="item in characters">
                <v-list-item
                  v-if="item.propertyAvatar && item.propertyAvatar.length > 0"
                  :key="item._id"
                  ripple
                  @click="onSelect(item)"
                >
                  <v-list-item-avatar>
                    <span><i class="fas fa-user be-icon"></i></span>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-html="item.title" />
                    <v-list-item-subtitle v-html="item.code" />
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="showed = false">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['select'],
  data() {
    return {
      showed: false,
    };
  },
  computed: {
    characters() {
      return this.$store.state.bookeditor.characters;
    },
  },
  methods: {
    show() {
      this.showed = true;
    },
    onSelect(item) {
      if (typeof this.select === 'function') {
        this.select(item);
      }
      this.showed = false;
    },
  },
};
</script>
